import React from "react"

import Image from "../../image"

const Sponsor = ({ title, sponsors, storybook }) => {
  return (
    <section data-theme="white">
      <div className="container container--lg margin-top--sm margin-bottom--lg">
        <p className="text--center margin-bottom--sm">{title}</p>

        <div className="col col--sm-3 col--sm-12 flex flex--space-around">
          {sponsors &&
            sponsors.map(sponsor => (
              <div
                key={sponsor.alt}
                className={"logo--sm "}
                style={{ width: "100%" }}
              >
                {storybook ? (
                  <img src="https://via.placeholder.com/100x60" alt="sb pic" />
                ) : (
                  <Image filename={sponsor.Filename} alt={sponsor.alt} />
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Sponsor
