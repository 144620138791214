import React, { useState, useEffect } from "react"

const ContactFormScript = ({ formId }) => {
  const marketoScriptId = "1056"
  const onSubmit = event => {
    if (typeof window !== "undefined") {
      window.jaSendSMS(event)
    }
  }

  //   <script src="//app-nld101.marketo.com/js/forms2/js/forms2.min.js"></script>
  // <form id="mktoForm_1056"></form>
  // <script>MktoForms2.loadForm("//app-nld101.marketo.com", "049-IFI-629", 1056);</script>
  // export default function MarketoForm({ formId }) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!document.getElementById(marketoScriptId)) {
      loadScript()
    } else {
      setIsLoaded(true)
    }
  }, [])

  useEffect(() => {
    isLoaded &&
      window.MktoForms2.loadForm(
        "//app-nld101.marketo.com",
        "049-IFI-629",
        1056
      )
  }, [isLoaded, formId])

  const loadScript = () => {
    var s = document.createElement("script")
    s.id = marketoScriptId
    s.type = "text/javascript"
    s.async = true
    s.src = "//app-nld101.marketo.com/js/forms2/js/forms2.min.js"
    s.onreadystatechange = function () {
      if (this.readyState === "complete" || this.readyState === "loaded") {
        setIsLoaded(true)
      }
    }
    s.onload = () => setIsLoaded(true)
    document.getElementsByTagName("head")[0].appendChild(s)
  }

  return (
    <section id="getStarted" data-theme="white">
      <div className="container container--lg margin-top--lg margin-bottom--lg margin---none">
        <div className="text--component text--center margin-bottom--md text-limit">
          <h2 className="text--center margin-bottom--md">Get started</h2>
        </div>
        <div className="grid grid--gap-lg">
          <div className="col col--10 col--md-6 text--center flex flex--column">
            <h3 className="margin-bottom--sm" style={{ color: "#5096E8" }}>
              Refer a patient
            </h3>
            <form id="jaSMSForm" onSubmit={onSubmit}>
              <input
                id="jaSMSFormField"
                className="form-control form-control--md form-control--white text--left"
                type="tel"
                name="tel"
                autoComplete="off"
                placeholder="Patient phone number"
                required
              />
              <button
                id="jaSMSFormSubmit"
                type="submit"
                className="btn btn--primary btn--md"
                style={{ marginTop: "0" }}
              >
                Submit
              </button>
            </form>
          </div>
          <div className="col col--10 col--md-6">
            <h3
              className="margin-bottom--sm text--center"
              style={{ color: "#5096E8" }}
            >
              Become a Referral Partner
            </h3>

            <form id={`mktoForm_${marketoScriptId}`}></form>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ContactFormScript
