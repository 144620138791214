import React from "react"

import Image from "../../image"

const bannerImageText = ({
  dataTheme,
  Filename,
  alt,
  text,
  subText,
  storybook,
}) => {
  return (
    <section data-theme={dataTheme}>
      <div className="container container--lg margin-top--md margin-bottom--md margin---none">
        <div className="grid grid--gap-xl flex--center-x flex--center-y">
          <div className="col col--10 col--sm-4 ">
            {storybook ? (
              <img src="https://via.placeholder.com/300x200" alt="sb pic" />
            ) : (
              <Image filename={Filename} alt={alt} />
            )}
          </div>
          <div className="col col--12 col--sm-6">
            <div className="text--component text--left list--checklist">
              <p className="text--md text--bold margin-bottom--sm">“{text}”</p>
              <p className="text--sm">{subText}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default bannerImageText
