import React from "react"

const Referral = ({
  title,
  ctaDataGoal,
  ctaHref,
  ctaClassname,
  ctaButtonText,
}) => (
  <section data-theme="white">
    <div className="container container--md margin-top--md margin-bottom--lg">
      <div className="text--component text--center margin-bottom--md">
        <p className="text--md col--md-12 align--center">{title}</p>
        <a data-goal={ctaDataGoal} href={ctaHref} className={ctaClassname}>
          {ctaButtonText}
        </a>
      </div>
      <div className="col col--12 col--lg-12">
        <div>
          <p className="text--xxs opacity--6 margin-top--md text--center">
            <a
              href="https://www.oarsijournal.com/article/S1063-4584(20)30334-4/fulltext"
              className="color--dark-grey-blue"
            >
              1) H. Nero, S. Lohmander, L.E. Dahlberg, Dell’Isola A, A.
              Cronström, Changes in Patients’ Wish to Undergo Joint Surgery
              While Participating in a 12-Month Digital Management Program for
              Hip and Knee Osteoarthritis (publication pending)
            </a>
          </p>
          <p className="text--xxs opacity--6 margin-top--xxxs text--center">
            <a
              className="color--dark-grey-blue"
              href="
			https://www.oarsijournal.com/article/S1063-4584(20)30117-5/fulltext"
            >
              2) H. Nero, S. Lohmander, L.E. Dahlberg. Improved patient outcomes
              by a first-line osteoarthritis self-management program delivered
              digitally. Osteoarthritis and Cartilage, Volume 28, SUPPLEMENT 1,
              S164-S165, April 01, 2020
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
)
export default Referral
