import * as React from 'react';
import { CTA_DATA_GOAL_CALL } from '@/src/common/consts/tracking';
import Button from '@components/Button';
import Layout from '../../components/layout';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import BannerImageText from '../../components/sections/bannerImageText/bannerImageText';
import Referral from '../../components/sections/referral/referral';
import ContactFormScript from '../../components/sections/contactFormScript/contactFormScript';
import Sponsor from '../../components/sections/sponsor/sponsor';
import ImageText from '../../components/sections/imageText/imageText';
import HeroListing from '../../components/sections/heroListing/HeroListing';
import Testimonial from '../../components/sections/testimonial/testimonial';
import GetStarted from '../../components/sections/getStarted/getStarted';

const IndexPage = () => (
  <Layout lang="us" headerWitdh="header" showFooter>
    <Segment id="15aFPY6NamUQUAN3oIEqSx0D2mNElDF7" />
    <Seo title="Patient-referral" language="en" />
    <HeroListing
      imageAlt="Patient referral"
      title="Are your patients suffering from chronic joint pain?"
      text="Joint Academy is an online physical therapy provider offering a clinically proven first-line treatment for chronic joint pain."
      titleButtonText="Want to refer a patient or become a referral partner?"
      ctaButton={(
        <Button
          dataGoal={CTA_DATA_GOAL_CALL}
          href="#getStarted"
          classname="btn btn--primary btn--lg margin-bottom--xs"
          text="get started"
        />
      )}
      list={[
        { li: 'Personal licensed physical therapist' },
        { li: 'Individualized exercise program' },
        { li: 'Education and progress tracking' },
        { li: 'Only 5 minutes a day' },
      ]}
      showCerts
    />
    <Sponsor
      title="We accept most insurances"
      sponsors={[
        {
          alt: 'medicare',
          Filename: 'medicare.png',
        },
        {
          alt: 'blueshieldca',
          Filename: 'blueshieldca.png',
        },
        {
          alt: 'humana',
          Filename: 'humana.png',
        },
        {
          alt: 'aetna',
          Filename: 'aetna.png',
        },
        {
          alt: 'united healthcare blue',
          Filename: 'united_healthcare_blue.png',
        },
      ]}
    />
    <Testimonial
      renderTitle
      renderText
      title="Leif Dahlberg, CMO, explains Joint Academy"
      titleClassName="text--sm"
      videoSrcURL="https://player.vimeo.com/video/362961288?color=fff&title=0&byline=0&portrait=0"
      videoTitle="Leif Dahlberg, CMO, explains Joint Academy"
    />
    <ImageText
      imgSize=""
      Filename="pyramid_us.png"
      alt="pyramid_us"
      Position=""
      title="The right treatment at the right time for every patient"
      text="Joint Academy makes it easy for you to follow national guidelines for osteoarthritis treatment, which in turn improves the quality of life for your patients."
    />
    <ImageText
      imgSize=""
      Filename="pain_chart_us@2x.png"
      alt="In-network with Humana"
      Position="rowRevers"
      title="Leading research and clinical results"
      text="Research, combined with first-class care from our licensed physical therapists, has been proven an effective approach to reduce osteoarthritis symptoms."
    />
    <ImageText
      imgSize=""
      Filename="55_42_us@2x.png"
      alt="55_42_us"
      Position=""
      title="No pills. No surgery. Just healthier joints."
      text="Our peer-reviewed studies and ongoing research show that Joint Academy patients change their minds about surgery, and quit painkillers."
    />
    <ImageText
      imgSize="col--lg-4"
      Filename="phone_chat_us@2x.png"
      alt="phone_chat_us"
      Position="rowRevers"
      title="Unlimited support from a physical therapist"
      text="Our treatment is delivered via a user friendly mobile app that gives patients direct access to a personal licensed physical therapist - available for support and guidance through the whole treatment program."
    />
    <Referral
      title="Want to refer a patient or become a referral partner?"
      ctaButtonText="GET STARTED"
      ctaDataGoal="Calendly Viewed"
      ctaHref="#getStarted"
      ctaClassname="btn btn--primary btn--lg"
    />

    <BannerImageText
      dataTheme="ice-blue"
      Filename="christian@2x.png"
      alt="christian"
      text="Joint Academy makes it easier to start osteoarthritis treatment
                in an early stage, which decreases the patient’s pain and
                restores their movement. This not only means an easier
                day-to-day life for them, but also patients that are more
                pleased with their doctor."
      subText="Christian, orthopedic specialist"
    />
    <GetStarted
      title="How to work with Joint Academy"
      text="We offer multiple tools to make it easy for you to offer your patients a clinically-proven, digital treatment for osteoarthritis from home - without waiting times. You can refer just one patient or you can sign up to be a referral partner. We send our referral partners all the tools they need, directly to their email, to successfully refer patients across their own platforms."
      renderSubTitle="Patient Referral Materials"
      cards={[
        {
          alt: 'App',
          Filename: 'referral_folder.jpg',
          title: 'Joint Academy folder',
          text:
              'Hand out folder to patients you think would benefit from using Joint Academy. It explains the treatment and how to get started.',
        },
        {
          alt: 'Insurence',
          Filename: 'referral_website.jpg',
          title: 'Website',
          text:
              'Direct patients to you own Joint Academy website where they can learn more and easily download the app.',
        },
        {
          alt: 'Pt',
          Filename: 'referral_banner.jpg',
          title: 'Email banner',
          text:
              'Use the banner in emails and newsletters to patients. Link to the website for easy access to Joint Academy.',
        },
        {
          alt: 'Treatment',
          Filename: 'referral_app',
          title: 'Referral tool',
          text:
              'Add the referral tool as a shortcut on your computer and phone to easily send a SMS to patients inviting them to Joint Academy.',
        },
      ]}
    />
    <ContactFormScript />
  </Layout>
);

export default IndexPage;
